<template>
  <div class="container-fluid">
    <div class="d-flex flex-column flex-md-row">
      <div class="col-md-6 col-xl-5 nopadding">
        <h2 class="font-weight-bolder mb-2 mt-2">
          {{ $t("The doctor’s health network") }}
        </h2>
        <h5 class="mb-3">
          {{
            $t(
              "NHP is a Norwegian carriere and knowledge tool for all doctors in Norway."
            )
          }}
          <span class="font-weight-bolder">
            {{ $t("All in one place") }}
          </span>
        </h5>
        <div v-if="!isAuthenticated">
          <b-button
            variant="primary"
            class="mb-1"
            @click="signUp"
          >
            <feather-icon
              class="mr-50"
              icon="PieChartIcon"
            />
            <span class="align-middle">
              {{ $t("Sign up for a free account") }}
            </span>
          </b-button>
        </div>
        <div
          v-if="!isAuthenticated"
          class="flex-box mb-2"
        >
          <!-- <b-img
            width="102"
            height="30"
            src="@/assets/images/logo/buypass.svg"
            alt="Buypass"
            class="d-inline-block mr-2"
          /> -->
          <b-img
            width="102"
            height="30"
            src="@/assets/images/logo/bankid.svg"
            alt="Bankid"
            class="d-inline-block"
          />
        </div>
        <h6 class="font-weight-bolder">
          {{ $t("In cooperation with") }}:
        </h6>
        <b-img
          width="88"
          height="31"
          src="@/assets/images/logo/innovation-norway-logo.svg"
          alt="innovation-norway"
          class="d-inline-block mr-1 mb-1"
        />
      </div>
      <div
        class="pl-sm-0 w-100 w-md-50 pl-md-1 ml-auto left-padding-only preview-video-logo"
        @mouseover="videoControls = 1"
        @mouseleave="videoControls = 0"
      >
        <div class="videoWrapper">
          <div
            v-if="!playerActive"
            class="video-player-actions d-flex align-items-center justify-content-center position-absolute"
          >
            <img
              :src="playIcon"
              alt="play-btn"
              @click="onPlay"
            >
          </div>
          <vue-vimeo-player
            ref="player-home"
            class="rounded"
            :options="options"
            video-id="814186659"
            @ready="onReady"
            @ended="onEnded"
            @pause="onPause"
          />
        </div>

        <!-- <a :href="prevLogoLink" class="preview-video-logo-link" target="_blank">
          <b-img :src="prevImgSrc" class="preview-video-logo-src" alt="prev logo" />
        </a> -->
      </div>
    </div>
    <div class="row match-height mt-4">
      <div class="col-md-6 col-lg-4 left-padding-only">
        <b-card
          img-src="@/assets/images/pages/home/jobs.svg"
          img-alt="jobs"
          img-top
          style="max-width: 100%"
          class="text-center scale-up"
        >
          <b-button
            href="#"
            variant="outline-primary"
            to="/jobs"
          >
            {{ $t("Available positions") }}
          </b-button>
        </b-card>
      </div>
      <div class="col-md-6 px-sm-0 px-md-1 col-lg-4 left-padding-only">
        <b-card
          img-src="@/assets/images/pages/home/course.svg"
          img-alt="course"
          img-top
          style="max-width: 100%"
          class="text-center scale-up"
        >
          <b-button
            href="#"
            variant="outline-primary"
            to="/course/webinares"
          >
            {{ $t("Webcasts and webinars") }}
          </b-button>
        </b-card>
      </div>
      <div class="col-md-12 px-sm-0 px-lg-1 col-lg-4 left-padding-only">
        <b-card
          img-src="@/assets/images/pages/home/product.svg"
          img-alt="product"
          img-top
          style="max-width: 100%"
          class="text-center w-md-50 scale-up"
        >
          <b-button
            href="#"
            variant="outline-primary"
            to="/marketplace"
          >
            {{ $t("For sale") }}
          </b-button>
        </b-card>
      </div>
    </div>
    <div class="row match-height mt-0">
      <div class="col-md-6 col-lg-4 left-padding-only">
        <b-card
          img-src="@/assets/images/pages/home/video.svg"
          img-alt="video"
          img-top
          style="max-width: 100%"
          class="text-center scale-up"
        >
          <b-button
            href="#"
            variant="outline-primary"
            to="/videos"
          >
            {{ $t("Video") }}
          </b-button>
        </b-card>
      </div>
      <div class="col-md-6 px-sm-0 pl-md-1 px-lg-1 col-lg-4 left-padding-only">
        <b-card
          img-src="@/assets/images/pages/home/audio.svg"
          img-alt="audio"
          img-top
          style="max-width: 100%"
          class="text-center scale-up"
        >
          <b-button
            href="#"
            variant="outline-primary"
            to="/audio"
          >
            {{ $t("Audio") }}
          </b-button>
        </b-card>
      </div>
      <div class="col-md-12 px-sm-0 px-lg-1 col-lg-4 left-padding-only">
        <b-card
          img-src="@/assets/images/pages/home/community.svg"
          img-alt="jobs"
          img-top
          style="max-width: 100%"
          class="text-center scale-up"
        >
          <b-button
            href="#"
            variant="outline-primary"
            to="/feed"
          >
            {{ $t("Community") }}
          </b-button>
        </b-card>
      </div>
    </div>
    <div class="row match-height mb-2">
      <div class="col-md-5 nopadding mb-2">
        <img
          src="@/assets/images/pages/home/medicine-image.svg"
          alt=""
        >
      </div>
      <div class="col-md-7 text-center justify-content-center mb-2">
        <h3 class="font-weight-bolder px-3 mt-2">
          {{ $t("About Norsk Helseportal") }}
        </h3>
        <h5 class="px-2 mb-0">
          {{ $t('Norsk Helseportal is a professional and career tool that brings together all doctors in Norway.') }}
        </h5>
        <h5 class="px-2">
          {{ $t('Everything in a safe place for doctors.') }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>

import playIcon from '@/assets/images/pages/home/play-icon.svg'
import pauseIcon from '@/assets/images/pages/home/pause-icon.svg'

import { BImg, BButton, BCard } from 'bootstrap-vue'
import { vueVimeoPlayer } from 'vue-vimeo-player'
import authMixin from '@/mixins/auth'

export default {
  components: {
    BImg,
    BButton,
    BCard,
    vueVimeoPlayer,
  },
  mixins: [authMixin],
  data() {
    return {
      playIcon,
      pauseIcon,
      options: {
        responsive: true,
        fullscreen: true,
        controls: true,
        dnt: true,
        interactive_markers: false,
        pip: false,
        play_button_position: 'None',
        quality_selector: false,
        title: false,
        transcript: false,
        vimeo_logo: false,
        watch_full_video: false,
        progress_bar: false,
        chromecast: false,
        airplay: false,
        byline: false,
        cc: false,
        playsinline: false,
        unmute_button: false,
      },
      playerReady: false,
      playerActive: false,
    }
  },
  computed: {
    prevImgSrc() {
      if (this.$store.getters['settings/prevImageVideoSrc'] !== null) {
        return this.$store.getters['settings/prevImageVideoSrc']
      }

      return require('@/assets/images/pages/default-prev-video-img-src.jpg')
    },
    prevLogoLink() {
      return this.$store.getters['settings/prevImageVideoLink']
    },
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated']
    },
  },
  methods: {
    onReady() {
      this.playerReady = true
    },
    onPlay() {
      if (this.playerReady) {
        this.$refs['player-home'].play()
        this.playerActive = true
      }
    },
    onPause() {
      this.$refs['player-home'].pause()
      this.playerActive = false
    },
    onEnded() {
      this.playerActive = false
    },
  },
}
</script>
